.register-cont .MuiButton-label{
    font-size: 15px;

}
.register-cont .MuiTypography-body2{
    font-size: 13px;

}

.register-cont .MuiTypography-h3{
    font-weight: 300;
}

.register-cont .registerSubmit{
    margin:24px 0px 16px;
    /*background: #111;*/
}

.otpInput input {
    font-size: 35px;
    width: 50px !important;
    margin: 10px;
    text-align:center;
    line-height:50px;
    font-size:30px;
    border:solid 1px #ccc;
    box-shadow:0 0 5px #ccc inset;
    outline:0;
    transition:all .2s ease-in-out;
    border-radius:3px
}
.otpInput input:focus {
    border-color:#1e2252;
    box-shadow:0 0 5px #1e2252 inset
}
.otpInput:nth-child(1){
    text-align: center !important;
}
.otpInput:nth-child(1) > div{
    justify-content: center;
}
.register-cont .MuiFormLabel-root {
    font-size: 20px !important;
}
.register-cont .MuiInputBase-root span{
    font-size: 15px !important;
}
.register-cont .MuiInputBase-input{
    font-size: 20px !important;
}
.register-cont .MuiTypography-colorTextSecondary {
    font-size: 20px !important;
}

.conf_signup span {
    font-weight: 700;
    color: #ed8323;
}
.signupmodal {
    font-size: 20px !important;
}

.MuiDialogTitle-root .MuiTypography-root{
    font-size: 20px !important;
}

.register-cont .MuiButton-textPrimary {
    background-color: #ed8323 !important;
    font-size: 15px !important;
    font-weight: 300;
    color: white !important;
}

.register-cont .MuiButton-textPrimary:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
}