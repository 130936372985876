.pull-right{
    float:right;
}
.charts {
    position: relative;
    cursor: default;
    width: 886px;
    height: 179px;
}

.vehicleform {
    color: whitesmoke !important;
}

.vehicle_name {
    font-size: 20px !important;
    margin-top: 5px !important;
}

.vehicle_info_paper17 {
    padding: 5px !important;
    text-align: center;
}

.vehicle_info .hrateleft {
    text-align: left !important;
}
.vehicle_info .hrateright {
    text-align: right !important;
}

.vehicle_info p {
    font-size: 13px;
    margin-bottom: 0px !important;
}
.vehicle_info{
    margin-bottom: 10px !important;
}

.vehicle_info h4 {
    color: thistle;
    font-size: 20px;
    padding-bottom: 0px !important;
    margin-bottom: 0px;
}

.vehicle_info .MuiGrid-item {
    padding-bottom: 2px !important;
}

.fab {
    margin: 0px;
    top: auto;
    right: 40px;
    bottom: 30px;
    left: auto;
    position: fixed;
};

.fab > .MuiFab-primary {
    background-color: #90caf9 !important;
    font-size: 20px !important;
}
.fab .MuiSvgIcon-root {
    font-size: 20px !important;
}

.vehicle_pagination {
    text-align: center !important;
}

.vehicle_pagination .MuiPagination-ul{
    margin-top: 20px;
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center !important;
    font-size: 15px !important;
}

.vehicle_pagination ul li {
    display: inline-block !important;
}

.modalMaxWidth1080 {
    text-align: center;
    vertical-align: middle;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px !important;
}

.modalMaxWidth1080 input {
    font-size: 20px !important;
}

.modalRate {
    margin-top: 10px;
    padding: 10px !important;
    text-align: center;
}

.addTextField3 {
    margin-right: 5px;
    margin-left: 5px;
}

.gridPopup {
    padding-top: 0px;
    margin-top: -20px;
}



.MuiSlider-markLabel{
    font-size: 13px !important;
}
.MuiChip-label{
    font-size: 15px !important;
}
.booking-item-price-calc .checkbox label{
    line-height: 27px;
    font-weight: 300;
}
.MuiSlider-root{
    color:#2f96b4 !important;
}
.v-price-slider .MuiSlider-valueLabel span{
    color:#fff !important;
    background:#2f96b4 !important;
    font-size: 11px;
}

.vehicle-listing {
}
.vehicle-listing .fa-search{
    font-size: 100px !important;
}
.vehicle-listing .search-icon{
    margin-top: 60px;
}
.vehicle-listing .not-found{
    font-size: 20px;
    margin-top: 20px;
    color: #d40029;
}

.DateRangePicker{
    width: 100%;
}
.DateRangePicker__Date{
    border: none !important;
}



.DateRangePicker__CalendarSelection{
    background-color: #3CB371;
    border: 1px solid #3CB371;
}
.DateRangePicker__LegendItemColorm,.DateRangePicker__LegendItemColor--selection{
    background-color: #3CB371;
}

.cal-date-listing.react-calendar__month-view__days__day--weekend{
    color:#111;
}

.cal-date-listing.react-calendar__tile--active{
    background-color: #3CB371;
    color:#fff;
}
.cal-date-listing.react-calendar__tile--active:hover{
    background-color: #3CB371 !important;
    color:#fff;
}
.cal-date-listing.react-calendar__tile--active:active{
    background-color: #3CB371 !important;
    color:#fff;
}
.cal-date-listing.react-calendar__tile--hasActive{
    background-color: #3CB371 !important;
    color:#fff;
}
.cal-date-listing.react-calendar__tile--hasActive:hover{
    background-color: #3CB371 !important;
    color:#fff;
}
.cal-date-listing.react-calendar__tile--hasActive:active{
    background-color: #3CB371 !important;
    color:#fff;
}

.react-calendar{
    border: none;
}
.list-title-price{
    color: #2f96b4;
    font-size: 15px;
}
.list-price{
    color: #999;
    font-size: 15px;
}
.price-list{
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    border-bottom: 1px dashed #d1d1d1;
}
.details-date-picker input{
    font-size: 20px;
}
.details-date-picker label{
    font-size: 18px !important;
}
.add-ons-chips{
    margin: 3px;
}
.terms-cont li{
    float: none;
    display: inline-block;
}
.proceed-green-btn{
    background: green !important;
    color: #fff !important;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.image-gallery-content.left .image-gallery-slide .image-gallery-image, .image-gallery-content.right .image-gallery-slide .image-gallery-image{
    max-height: 320px !important;
}

.fullscreen-modal .image-gallery-content.right .image-gallery-slide .image-gallery-image{
    max-height: none !important;
}
.product-info-list{
    margin: 5px !important;
}
.product-info-list span{
    font-size: 15px;
}
.product-info-list .info-list-val{
    font-size: 15px;
    margin-top: -2px;
}

.product-info-list .MuiTypography-colorTextSecondary{
    font-size: 20px;
}
.product-type-badge .MuiBadge-badge{
    font-size: 15px;
    background: #2f96b4;
    color:#fff;
}
