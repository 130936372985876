.home-date-picker input{
    font-size: 25px;
}
.home-date-picker label{
    font-size: 18px !important;
}
.home-no-days .MuiInputLabel-animated{
    font-size: 19px !important;
}
.home-no-days input{
    font-size: 25px !important;
}
.home-search-right{
    margin-top: 0px !important;
}

.process-steps {
    margin: 0 0 40px;
    list-style: none
}
.process-steps .round{
    background: #fff;
}
.process-steps .active-prog {
    background: #2f96b4;
    color:#fff;
}

.process-steps li {
    position: relative;
    text-align: center;
    overflow: hidden
}

.process-steps li .i-circled {
    margin-bottom: 0 !important
}

.process-steps li .i-bordered {
    background-color: #fff
}

.process-steps li h5 {
    margin: 15px 0 0 0;
    font-size: 1rem;
    font-weight: 300
}

.process-steps li.active h5,
.process-steps li.ui-tabs-active h5 {
    font-weight: 600;
    color: #1abc9c
}

.process-steps li.active a,
.process-steps li.ui-tabs-active a {
    background-color: #1abc9c !important;
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
    border-color: #1abc9c !important
}


.process-steps li::after,
.process-steps li::before {
    content: '';
    position: absolute;
    top: 30px;
    left: 0;
    margin-left: -26px;
    width: 50%;
    border-top: 1px dashed #ddd
}
.process-steps li::after {
    left: auto;
    right: 0;
    margin: 0 -26px 0 0
}
.process-steps li:first-child::before,
.process-steps li:last-child::after {
    display: none
}


:active,
:focus {
    outline: 0 !important
}


@media (max-width:992px) {
    .home-date-picker input{
        font-size: 19px;
    }
    .home-no-days input{
        font-size: 19px !important;
    }
}

#owl-carousel-review .item{
    background: #fff;
    padding: 20px 20px;
    margin: 5px;
    color: #565656;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-align: ;
    height:300px;

}

#owl-carousel-review .item-m{
    background: #fff;
    padding: 20px 20px;
    margin: 5px;
    color: #565656;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-align: ;
    height:400px;

}

#owl-carousel-review blockquote{
    font-style: normal;
    font-size: 14px;

}

.owl-nav div {
  position: absolute;
  top: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: block;
  -webkit-box-shadow: 0 0 0 1px #fff;
  box-shadow: 0 0 0 1px #fff;
  margin: -30px 0 0 0;
  background: rgba(0,0,0,0.2) !important;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
  font-size: 17px;
  -webkit-border-radius: 50% !important;
  border-radius: 50% !important;
  text-align: center;
  color: #fff !important;
  padding: 0 !important;

}
.owl-nav div:hover {
  background: #ed8323;
  -webkit-box-shadow: 0 0 0 1px #ed8323;
  box-shadow: 0 0 0 1px #ed8323;
}
.owl-nav div:before {
  font-family: 'FontAwesome';
}
.owl-nav div.owl-next {
  right: 0;
}
.owl-nav div.owl-next:before {
  content: '\f105';
}
.owl-nav div.owl-prev {
  left: 0;
}
.owl-nav div.owl-prev:before {
  content: '\f104';
}
.owl-theme .owl-dots .owl-dot span{
    background: #ed8323 !important;
    opacity: 0.5;
}
.owl-theme .owl-dots .owl-dot.active span{
    background: #ed8323 !important;
    opacity: 1;
}
