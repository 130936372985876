.about-us-cont .bg-parallax{

}
.about-us-cont .bg-holder{
    overflow: hidden;

}
.faq-cont p{
    font-size: 18px;
    line-height: 30px

}
