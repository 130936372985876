.primary-color-earental{
    background: #4d4d4d !important;
}
.dashboardName {
    text-align: center !important;
    vertical-align: middle !important;
    position: absolute !important;
    height: 100px !important;
    margin: auto !important;
    color: red !important;
    width: 100% !important;
}
.dashboardName h1 {
    color: blue !important;
    vertical-align: middle !important;
    text-align: center !important;
    margin-top: 15px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.dashboardLogo {
    width: auto !important;
    vertical-align: middle !important;
    text-align: center !important;
    margin-top: 35px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    max-width: 110px;
}

.dashboardhome .MuiTypography-colorPrimary{
    color: #90caf9 !important;
}

/* DRAWER */
.list-nav-cont .MuiTypography-displayBlock{
    font-size: 14px;
}
.list-nav-cont .MuiSvgIcon-root{
    font-size: 20px;
}
.list-nav-cont .MuiListSubheader-root{
    font-size: 15px;
}

.list-nav-cont .active {
    background: #2f96b4;
    color: #fff;
    text-decoration: none;
}
.list-nav-cont .active:hover {
    background: #2f96b4;
    color: #fff;
    text-decoration: none;
}
/* SCHEDULE MODULE */
.schedule-cont .MuiDataGrid-root{
    color:#111 !important;
    font-size: 15px;
}
.schedule-cont{
    padding: 20px;
}
.schedule-table .MuiSvgIcon-root{
    color:#111 !important;
    font-size: 20px;
}

.schedule-table  .MuiPaper-root{
    background-color: #fff;
}
.schedule-table th{
    background-color: #fff;
    color: rgba(0, 0, 0, 0.54);
    font-size: 15px;
}
.schedule-table td{
    font-size: 13px !important;
}
.schedule-table tr td:last-child {
    background: #fff !important;
}
.schedule-table .MuiTablePagination-root{
    color:#111;
}

.schedule-table .MuiPaper-root{
    box-shadow: none;
}


.schedule-table .MuiToolbar-root{
    padding-left: 0;
}
.schedule-table .MuiTypography-root{
    font-size: 22px;
    border-bottom: 1px solid #d1d1d1;
}
.schedule-table .MuiTable-root{
    margin-bottom: 15px;
}
.table-search{
    float: right;
    z-index: 1;
    position: absolute !important;
    top: 65px;
    right: 20px;
    display: none !important;
}

.reason-text textarea{
    font-size: 14px !important;
    line-height: 19px;
}
.change-product-list span{
    font-size: 14px;
    font-weight: 700;
}
.change-product-list p{
    font-size: 13px;
}


/* VEHICLE */
.vehicle-cont{
    padding: 20px;
}
.vehicle-cont .fab-cont-right{
    position: fixed;
    right: 20px;
    bottom: 20px;
}


/* CHARTS */
.chart-cont{
    padding: 20px;
}

/* AGREEMENT */
.agreement-cont{
    padding: 20px;
}

.agreement-cont .agreement-wrapper{
    width: 100%;
    margin: 0 auto;
}
.agreement-cont .agreement-editor{
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
    height: 80% !important;
}
