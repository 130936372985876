.login-cont .MuiButton-label{
    font-size: 15px;

}
.login-cont .MuiTypography-body2{
    font-size: 13px;

}
.login-cont .MuiTypography-h3{
    font-weight: 300;
}

.login-cont .MuiTypography-h6{
    font-weight: 300;
}

.login-cont .loginPaper{
    display: flex;
    margin-top: 64px;
    align-items: center;
    flex-direction: column;
}
.login-cont .loginForm{

    width: 100%;
    margin-top: 24px;
}

.login-cont .loginSubmit{
    margin: 24px 0px 16px;
    /*background: red;*/
    height:40px;
}

.login-cont .MuiFormLabel-root {
  font-size: 20px !important;
}
.login-cont .MuiInputBase-root span{
  font-size: 15px !important;
}
.login-cont .MuiInputBase-input{
  font-size: 20px !important;
}
.login-cont .MuiTypography-colorTextSecondary {
  font-size: 20px !important;
}