.user-profile-sidebar .user-profile-nav > li > a{
    font-size: 14px;
}
.user-profile-statictics > li p{
    font-size: 15px;
}
.profile-cont .user-profile-statictics > li{
    padding-top: 35px !important;
    margin-right: 10px;
    margin-top: 5px;

}
.profile-cont .user-profile-statictics > li .user-profile-statictics-icon{
    margin-bottom: 30px;
}

.profile-cont a.active{
    background: #2f96b4;
    text-decoration: none;
    color: #fff !important;
}


.profile-cont .MuiTypography-caption, .profile-cont .MuiTypography-body2{
    font-size: 13px!important;
}

.profile-cont .driver-card .MuiSvgIcon-root{
    font-size: 24px !important;
}

.profile-cont .avatar-cont{
    margin-bottom: 10px;
}
.profile-cont .avatar-cont .profile-avatar{
    width: 120px !important;
    height: 120px !important;
    font-size: 45px;
}
.profile-cont .profile-inputs input{
    font-size: 20px;
}
.profile-cont .profile-inputs .MuiSelect-selectMenu{
    font-size: 20px;
}
.profile-cont .profile-inputs label{
    font-size: 20px;
}

.profile-cont .MuiButton-label{
    font-size: 15px;
}

.profile-cont label{
    font-size: 17px;
}
.profile-cont .MuiAlert-message{
    font-size: 15px;
}
#gst-registered{
    font-size: 20px;
}

.dialogContentCustom{
    font-size: 15px !important;
}
.dialogContentCustom .alert-dialog{
    font-size: 15px !important;
}
.img-lessor-signature{
    width: 150px;
    margin-bottom: 15px;
}

.lessor-agreement ul,.lessor-agreement ol {
    margin-left: 20px;
}
